import React, { Component } from "react";

class Hero extends Component {
  state = { didInit: false };
  render() {
    const { heading, title, description, image } = this.props;
    return (
      <div className="hero">
        <div className="heading">{heading}</div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        {image && (
          <img className="img-fluid mt-4" src={image} alt="banner"></img>
        )}
      </div>
    );
  }
}

export default Hero;
