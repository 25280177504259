import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./loader.js";
import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import ResidentialHome from "./pages/ResidentialHome";
// import Commercial from "./pages/Commercial";
import Contact from "./pages/Contact";
import PropertyDetails from "./pages/PropertyDetails";
import Policy from "./pages/Policy.js";

const CONTACT = [
  {
    title: "Residential Condo",
    icon: "/images/ic_res_condo.svg",
    items: [
      {
        name: "83 REDPATH",
        address: [
          "83 Redpath Avenue",
          "Toronto, ON M4S 0A2",
          "T. 416-485-4342"
        ],
        URL: { name: "83redpath.com", link: "http://www.83redpath.com" }
      },
      {
        name: "THE BENVENUTO",
        address: [
          "1 Benvenuto Pl",
          "Toronto, ON M4V 2L1",
          "T. 416-967-7128 (by Appointment Only)"
        ],
        URL: { name: "thebenvenuto.com", link: "http://www.thebenvenuto.com" }
      },
      {
        name: "293 THE KINGSWAY",
        address: [
          "289 The Kingsway",
          "Etobicoke, ON M9A 3T9",
          "T. 647-291-0658"
        ],
        URL: { name: "293kingsway.com", link: "http://www.293kingsway.com" }
      }
    ]
  },
  {
    title: "Residential Rental",
    icon: "/images/ic_res_rental.svg",
    items: [
      {
        name: "18 BROWNLOW",
        address: ["18 Brownlow Ave", "Toronto, ON M4S 2K8", "T. 416-485-4342"],
        URL: { name: "18brownlow.com", link: "http://www.18brownlow.com" },
        hours: [
          "Monday, Tuesday &\nThursday: 10 am - 5 pm",
          "Wednesday & Friday: 10 am - 7 pm",
          "Saturday: 12 pm - 5 pm"
        ]
      },
      {
        name: "289 THE KINGSWAY",
        address: ["289 The Kingsway", "Toronto, ON M9A 5E2", "T. 416-231-4071"],
        URL: { name: "thekingsview.com", link: "http://www.thekingsview.com" },
        hours: [
          "Monday, Tuesday & Thursday: 10 am - 5 pm",
          "Wednesday & Friday: 10 am - 7 pm",
          "Saturday: 12 pm - 5 pm"
        ]
      }
    ]
  },
  {
    title: "Commercial",
    icon: "/images/ic_commercial.svg",
    items: [
      {
        name: "83 REDPATH",
        address: [
          "83 Redpath Avenue",
          "Toronto, ON M4S 0A2",
          "T. 416-485-4342"
        ],
        URL: { name: "83redpath.com", link: "http://www.83redpath.com" }
      }
    ]
  }
];

const FEATURED = [
  {
    id: 1,
    banner: "/images/Hero-1.jpg",
    icon: "/images/Thumbnail-1.jpg",
    title: "Monza Condos",
    sub_title: "Monza",
    sub_title_two: "Selling now in Toronto",
    link: "http://monzacondo.com/"
  },
  {
    id: 2,
    banner: "/images/Hero-2.jpg",
    icon: "/images/Thumbnail-2.jpg",
    title: "Monza Condos",
    sub_title: "Monza",
    sub_title_two: "Selling now in Toronto",
    link: "http://monzacondo.com/"
  },
  {
    id: 3,
    banner: "/images/Hero-3.jpg",
    icon: "/images/Thumbnail-3.jpg",
    title: "Monza Condos",
    sub_title: "Monza",
    sub_title_two: "Selling now in Toronto",
    link: "http://monzacondo.com/"
  }
];
const CONTENT = [
  {
    name: "Residential Condo",
    icon: "/images/ic_res_condo.svg",
    link: "/residential/condo",
    heading: "A Stunning Collection of",
    title: "Residential\nCondo",
    description:
      "A developer with an intense passion for creative, urban, infill projects, The Benvenuto Group uses their vision and vast experience in creating some of the most prestigious condominium projects in Toronto and Montreal. The team works with top financial institutions, award winning architects, world renowned interior designers and top quality construction professionals to deliver projects of exceptional quality, on time and on budget.",
    subItems: [
      {
        name: "Toronto",
        region: "Ontario",
        items: [
          {
            name: "Monza",
            link: "http://monzacondo.com/",
            external: true,
            banner_alt: "/images/monza_banner.png",
            banner: "/images/monza_banner.jpg",
            home_hover:"/images/monza-hover",
            gmaps:
              "https://maps.google.com/maps?q=monza&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.679859,
            long: -79.432965,
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in pretium ex. Nam eget leo mi. Quisque vitae tortor at metus ullamcorper mattis a et ipsum. Sed eros libero, dapibus in laoreet vel, pellentesque non augue. Duis convallis, erat eget pulvinar ullamcorper, velit sem iaculis neque, at fringilla lacus libero eu nisl. Donec facilisis, eros sed volutpat finibus, tellus tortor blandit mauris, eu facilisis ligula sapien nec est. Nam feugiat odio justo, vitae volutpat augue venenatis et. Sed fringilla faucibus fermentum. Morbi dapibus, nulla vitae feugiat convallis, purus sapien pharetra turpis, et finibus lectus mi non purus. Sed sem orci, sollicitudin sagittis sem id, dapibus condimentum sem.\nNullam ut fringilla lacus, non dictum enim. Pellentesque ornare eros lacinia, placerat augue ac, bibendum odio. Proin lobortis lorem sit amet justo tempus, eget mollis massa facilisis. Morbi ac purus arcu. In vitae purus sollicitudin, ultricies nulla ut, laoreet est. Praesent ut lacinia neque. Nam fringilla viverra sem nec elementum. Donec fringilla erat eros, vitae condimentum eros maximus vitae.\nCras luctus aliquam nulla id rhoncus. Vestibulum tristique massa nec ullamcorper tempus. In nec enim ut est viverra ultricies. Donec cursus arcu lacinia est hendrerit venenatis. Ut eget lorem luctus dui tristique maximus. Cras in imperdiet nisl. Vestibulum finibus dictum tempus. Nunc interdum pellentesque luctus. Fusce dictum massa id porttitor ultricies. Vestibulum ac tempor nulla. Praesent dolor quam, egestas quis orci placerat, accumsan auctor est.",
            details: {
              data: "todo"
            },
            selling: true,
          },
          {
            name: "293 The Kingsway",
            link: "/residential/condo/293kingsway",
            banner: "/images/kingsway-banner.png",
            home_hover:"/images/293kingsway-hover",
            logo: "/images/kingsway-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=293%20kingsway&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.663628,
            long: -79.520749,
            description:
              "Welcome to 293 The Kingsway. A master-planned condominium community within a beautiful park-like setting, mere steps from vibrant urban amenities. A rare opportunity to purchase in Toronto’s most prestigious Kingsway neighbourhood, amidst million-dollar-plus mansions and leafy boulevards.",
            details: {
              Location: "Toronto, ON",
              "Stories": 7,
              "Interior Designer": "Patton Design Studio",
              "Total GFA (sf)": "443,753 sf",
              "Originally Built": "2019",
              Architect: "Quadriangle Architects",
              URL: { text: "293kingsway.com", link: "http://293kingsway.com/" }
            },
            gallery: [
              "/images/kingsway1f.png",
              "/images/kingsway2f.png",
              "/images/kingsway3f.png",
            ]
          },
          {
            name: "83 Redpath",
            link: "/residential/condo/redpath",
            banner: "/images/redpath-banner.png",
            home_hover:"/images/redpath-hover",
            customStyle:{transform: "translateX(-30px)"},
            logo: "/images/redpath-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=83%20redpath&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.706528,
            long: -79.391954,
            description:
              "Winner of the 2011 Pug Award for Best New Residential Building in Toronto, 83 Redpath is a magnificent mid-town condominium whose architecture creates two dramatic and distinctive common areas – the open air roof top and the dramatic entrance created by a floating cantilever spanning the two-storey lobby.\n\nJoining the condominium suites is a limited edition of custom-designed townhomes. Each is a masterpiece of simple, subtle, striking architecture featuring contemporary exteriors, exceptional interiors and rooftop terraces. In addition, smart, environmentally friendly initiatives have been incorporated throughout the entire building’s design process, focusing on conservation, sustainability, and healthier living.\n\nWith 83 Redpath’s flowing suite layouts, generous balconies and fantastic amenities, owners have the opportunity to enjoy life in a sophisticated setting perfectly poised between the electric hum of Yonge and Eglinton and the more relaxed and eclectic charm of the Bayview and Mt. Pleasant area.",
            details: {
              "Stories": 21,
              Location: "Toronto, ON",
              "Interior Designer": "Bryon Patton & Associates",
              "Total GFA (sf)": "200,000 sf",
              "Originally Built": "2010",
              Architect: "Andco Architects",
              URL: { text: "83redpath.com", link: "http://www.83redpath.com/" }
            },
            gallery: [
              "/images/redpath1f.png",
              "/images/redpath2f.png",
              "/images/redpath3f.png",
            ]
          },
          {
            name: "The Benvenuto",
            link: "/residential/condo/benvenuto",
            banner: "/images/benvenuto-banner.png",
            home_hover:"/images/benvenuto-hover",
            customStyle:{transform: "scale(1.1) translateY(-20px)"},
            logo: "/images/benvenuto-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=the%20benvenuto&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.681837,
            long: -79.400100,
            description:
              "The Benvenuto, a masterpiece of architect Peter Dickinson, has represented modern luxury living in Toronto since 1955. Now this important landmark has been refurbished and reborn, offering the finest of luxury features, finishes and amenities to complement the building’s timeless original design.\n\nWith its distinctive porte cochere entrance and its valet service, 24-hour concierge and day porter at the front desk, The Benvenuto welcomes residents and their guests into a spectacular lobby with breathtaking city views. Suites are sumptuously appointed with exotic hardwood flooring, custom kitchens with natural granite countertops and marble floors, spacious luxurious bathrooms with cast iron soaker tubs, large walk-in closets, and multiple, spacious balconies providing incomparable vistas.\n\nOffering the best of midtown living, The Benvenuto is steps from the action of Yorkville, the shops of Yonge and Summerhill, the trails of the Nordheimer Ravine, and the charm of Forest Hill Village.",
            details: {
              "Stories": 7,
              Location: "Toronto, ON",
              "Interior Designer": "Bryon Patton & Associates",
              "Total GFA (sf)": "200,000 sf",
              "Originally Built": "1955",
              "Reborn Build": "2005",
              Architect: "Peter Dickinson",
              URL: {
                text: "thebenvenuto.com",
                link: "http://thebenvenuto.com/"
              }
            },
            gallery: [
              "/images/benvenuto1f.png",
              "/images/benvenuto2f.png",
            ]
          },
        ]
      },
      {
        name: "Montreal",
        region: "Quebec",
        items: [
          {
            name: "Le Cour Langhorne",
            link: "/residential/condo/langhorne",
            banner: "/images/langhorne-banner.png",
            home_hover: "/images/langhorne-hover",
            customStyle:{transform: "translateY(20px)"},
            logo: "/images/langhorne-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=Le%20Cour%20Langhorne&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 45.483513,
            long: -73.638947,
            description:
              "High quality, new single family homes are becoming increasingly difficult to find in Montreal’s finest neighborhoods. Now LeCour Langhorne, a phased luxury townhome development, offers a fabulous parkside location close to all of the area’s many amenities\n\nAn intimate infill project at the corner of Langhorne Avenue and Macdonald Avenue, Le Cour Langhorne is set in a quiet, safe neighborhood in the heart of Montreal’s west end.\n\nThe townhomes front on Langhorne Park, a green public space soon to be re-designed and re-landscaped, and are steps from quality public and private schools. They are also just minutes from trendy Monkland Avenue shopping and dining, Queen Mary Road retail, and hospitals, and offer convenient access to both public transit and the Decarie Expressway.",
            details: {
              "Stories": 3,
              Location: "Montreal, QC",
              "Interior Designer": "Bryon Patton & Associates",
              "Total GFA (sf)": "75,000 sf",
              "Launch Date": "Summer 2016",
              Architect: "NEUF Architects",
              URL: {
                text: "lecourlanghorne.com",
                link: "http://lecourlanghorne.com/"
              }
            },
            gallery: [
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
            ]
          },
          {
            name: "Le Peterson",
            link: "/residential/condo/peterson",
            banner: "/images/peterson-banner.png",
            home_hover:"/images/peterson-hover",
            customStyle:{transform: "translateX(-30px)"},
            logo: "/images/peterson-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=le%20peterson&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 45.507077,
            long: -73.570904,
            description:
              "Overlooking Montreal’s Quartier des spectacles, Le Peterson combines daring architecture and sophisticated design. Featuring luxurious and spacious designs, combined with outstanding amenities, it is conveniently situated in one of the city’s most vibrant and sought-after urban neighbourhoods.\n\nLe Peterson provides a choice of superbly appointed lofts with 18’ ceilings, luxurious condominium suites with huge curved balconies, and spectacular penthouse residences with panoramic views and outdoor spaces that showcase the Montreal skyline in breathtaking fashion.\n\nWith social and recreational amenities on both the ground level and 28th floor, Le Peterson is in the very centre of it all, minutes from Place des Festivals, Place des Arts, Place Ville Marie, McGill University, UQAM and CHUM Superhospital, with easy access to the Metro.",
            details: {
              "Stories": 31,
              Location: "Montreal, QC",
              "Interior Designer": "Bryon Patton & Associates",
              "Total GFA (sf)": "350,000 sf",
              "Originally Built": "2016",
              Architect: "NEUF Architects",
              URL: {
                text: "lepeterson.com",
                link: "http://lepeterson.com/en/"
              }
            },
            gallery: [
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
            ]
          },
          {
            name: "ONYX",
            link: "/residential/condo/onyx",
            banner: "/images/onyx-banner.png",
            home_hover:"/images/onyx-hover",
            logo: "/images/onyx-banner-logo.png",
            gmaps:
              "https://maps.google.com/maps?q=ONYX&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 45.496034,
            long: -73.616875,
            description:
              "The sleek contemporary design of ONYX brings a bold new dimension to the corner of Avenue Decelles and Avenue Troie in Montreal. A fashionable and stylish 10-storey statement, it rises in a vibrant, urban neighbourhood steps from cafes and boutiques.\n\nThe Benvenuto Group’s renowned design team has created a collection of suites with sweeping, breathtaking views. Expansive floor to ceiling windows, intelligent layouts and gorgeous finishes create a haven of chic living in the heart of the city. Each suite features its own generous balcony, ideal for entertaining or watching the sun slowly set behind the city skyline.\n\nOffering the amenities of a fine boutique hotel, ONYX offers immediate access to the city’s largest green space, Mount Royal Park. Beaver Lake and miles of walking trails are also at the building’s doorstep, with downtown just minutes away via the Metro station nearby.",
            details: {
              "Stories": 3,
              Location: "Montreal, QC",
              "Interior Designer": "Bryon Patton & Associates",
              "Total GFA (sf)": "100,000 sf",
              "Originally Built": "2013",
              Architect: "Kfa-Karl Fischer",
              URL: {
                text: "onyxmontreal.com",
                link: "http://onyxmontreal.com/"
              }
            },
            gallery: [
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
            ]
          },
        ]
      },
    ]
  },
  {
    name: "Residential Rental",
    icon: "/images/ic_res_rental.svg",
    link: "/residential/rental",
    heading: "Beautiful Selection of",
    title: "Residential\nRental",
    description:
      "In addition to the array of luxury condominiums in its portfolio, The Benvenuto Group has been behind some of Toronto’s most prestigious rental properties. Existing apartments including 18 Brownlow and 289 The Kingsway were re-visioned and transformed by the Group through extensive renovations including exterior restoration, new amenity areas, and totally renovated suites.",
    limit_banner_height:true,
    subItems: [
      {
        name: "Toronto",
        region: "Ontario",
        items: [
          {
            name: "18 Brownlow",
            link: "/residential/rental/brownlow",
            banner: "/images/rental-brownlow-banner.png",
            home_hover:"/images/brownlow-hover",
            customStyle:{transform: "scale(1.5) translate(-160px, -105px)"},
            description:
              "18 Brownlow is a prime example of the Benvenuto Group's vision. After acquiring the 40 year-old building and its surrounding property in mid-town Toronto, comprehensive repositioning began in earnest. Exterior restoration, an impressive modern lobby, a resort-style pool, magnificent landscaping and totally renovated suites filled with designer touches transformed 18 Brownlow into the most sought-after rental property in the area.\n\nCreative thinking propelled our team to further maximize the potential of the site. Excavating the extensive underground parking garage and replacing it with a 3-storey facility freed up enough land to erect a luxury condo project facing the adjacent street at 83 Redpath.",
            gmaps:
              "https://maps.google.com/maps?q=18%20brownlow&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.706350,
            long: -79.391191,
            details: {
              "Stories": 19,
              Location: "Toronto (Etobicoke), ON",
              "Suite Size": "Bachelor, One, Two & Three Bedroom",
              "Originally Built": "1964",
              "Reborn Built": "2005",
              URL: {
                text: "18brownlow.com",
                link: "http://18brownlow.com/"
              }
            },
            gallery: [
              "/images/brownlow-1.jpg",
              "/images/brownlow-2.jpg",
              "/images/brownlow-3.jpg",
            ],
            leasing: true,
          },
          {
            name: "289 The Kingsway",
            link: "/residential/rental/kingsway",
            home_hover:"/images/289kingsway-hover",
            customStyle:{transform: "scale(1.4) translate(-140px, -105px)"},
            banner: "/images/rental-kingsway-banner.png",
            description:
              "289 The Kingsway was extensively renovated to meet the needs of luxury renters. The property received an award from the Federation of Rental Property owners, in recognition of the exceptional re-positioning of this fine building. The property is ideally located across from the Humbertown Shopping Centre and is just steps from the shops, restaurants and professional amenities that the nearby Kingsway shopping district offers.\n\nAmong the building's many features are: on-site superintendent services, resident service management personnel, air-conditioning, indoor/outdoor parking and storage. Situated just 3 minutes from the TTC station and offering easy access to Highways 401, 427 and the QEW.",
            gmaps:
              "https://maps.google.com/maps?q=289%20kingsway&t=&z=15&ie=UTF8&iwloc=&output=embed",
            lat: 43.663128,
            long: -79.519592,
            details: {
              "Stories": 17,
              Location: "Toronto (Etobicoke), ON",
              "Suite Size": "Bachelor, One, Two & Three Bedroom",
              Architect: "WZMH Architects",
              "Originally Built": "1960",
              "Reborn Built": "2009",
              URL: {
                text: "thekingsview.com",
                link: "http://thekingsview.com/"
              }
            },
            gallery: [
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
              "/images/gallery-placeholder.jpg",
            ],
            leasing: true,
          }
        ]
      }
    ]
  }
  // { name: "Commercial", link: "/commercial" }
  // { name: "Contact Us", link: "/contact-us" }
];
class App extends Component {
  render() {
    const { history } = this.props;
    return (
      <BrowserRouter>
        <div>
          <Navbar history={history} content={CONTENT} />
          <div className="content-app">
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnter={false}
              transitionLeave={false}
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => (
                    <Home
                      {...props}
                      content={[CONTENT[0], CONTENT[1]]}
                      featured={FEATURED}
                    />
                  )}
                />
                <Route
                  path="/residential/condo/:slug"
                  render={props => (
                    <PropertyDetails {...props} content={CONTENT} />
                  )}
                />
                <Route
                  path="/residential/rental/:slug"
                  render={props => (
                    <PropertyDetails {...props} content={CONTENT} />
                  )}
                />
                {/* <Route exact path="/commercial" component={Commercial} /> */}
                <Route
                  exact
                  path="/contact-us"
                  render={props => <Contact {...props} content={CONTACT} />}
                />
                <Route exact path="/policy" component={Policy} />
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </ReactCSSTransitionGroup>
          </div>
          <Footer history={history} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
