import React, { Component } from "react";
import "./Navbar.css";

class Navbar extends Component {
  state = { didInit: false, hamburgerOpen: false };
  onNavHamburgerClick = () => {
    const { hamburgerOpen } = this.state;
    this.setState({ hamburgerOpen: !hamburgerOpen });
  };
  onNavDropdownClick = (e) => {
    e.preventDefault();
  };
  render() {
    const { content } = this.props;
    const { hamburgerOpen } = this.state;
    const items = content.map((item, index) => (
      <li
        key={index}
        className={
          "nav-item dropdown" +
          (window.location.pathname === item.link ? " active-nav" : "")
        }
      >
        {item.subItems ? (
          <React.Fragment>
            <a className="nav-link dropbtn only-desktop" href="#" onClick={this.onNavDropdownClick}>
              {item.name}
              <div className="fas fa-sort-down"></div>{" "}
            </a>
            <a
              className="nav-link dropbtn only-mobile"
              href="javascript:void(0)"
            >
              {item.name}
              <div className="fas fa-sort-down"></div>{" "}
            </a>
            <div className="dropdown-content">
              {item.subItems.map((subItem, index) => (
                <React.Fragment key={index}>
                  <div className="dropdown-section-heading">{subItem.name}</div>
                  {subItem.items.map((item, index) => {
                    let label;
                    if (item.selling) {
                      label = (<span className="tag selling">Selling Now</span>);
                    }
                    else if (item.leasing) {
                      label = (<span className="tag leasing">Leasing Now</span>);
                    }
                    if (item.specialType === 2) {
                      return (
                        <a key={index} className="special" href={item.link}>
                          {item.name}
                          {label}
                        </a>
                      );
                    } else if (item.specialType === 1 || item.external === true) {
                      return (
                        <a
                          key={index}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                          {label}
                        </a>
                      );
                    } else {
                      return (
                        <a key={index} href={item.link}>
                          {item.name}
                          {label}
                        </a>
                      );
                    }
                  })}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <a className="nav-link" href={item.link}>
            {item.name}
          </a>
        )}
      </li>
    ));
    return (
      <div className="sticky-top">
        <div className="navbar-wrapper container">
          <nav className="navbar navbar-expand-md navbar-light">
            <a className="navbar-brand" href="/">
              <img src="/images/benvenuto_logo.svg" alt="logo" />
            </a>
            <button
              className={
                "navbar-toggler hamburger hamburger--collapse" +
                (hamburgerOpen ? " is-active" : "")
              }
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.onNavHamburgerClick}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="nav-menu-heading nav-item dropdown">MENU</li>
                {items}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Navbar;
