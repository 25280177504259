import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  state = { didInit: false };
  render() {
    return (
      <div className="footer-root d-flex">
        <div className="container d-flex flex-column pt-5 pb-5">
          <div className="row  no-gutters d-flex flex-xs-column">
            <div className="col-md-3">
              <a
                style={{ marginLeft: "-12px" }}
                className="navbar-brand"
                href="/"
              >
                <img src="/images/benvenuto_logo.svg" alt="logo" />
              </a>
            </div>
            <div className="col-md-3">
              <b>Address</b>
              <br /> <br />
              <div className="address">
                132 - 1 Benvenuto Place <br />
                Toronto ON M4V 2L1 <br /> <br />
              </div>
            </div>
            <div className="col-md-3">
              <b>Contact Information</b>
              <br /> <br />
              <div className="address">
                t.&nbsp;647-339-8996 <br />
                e.&nbsp;info@benvenutogroup.com <br /> <br />
              </div>
            </div>
            <div className="col-md-3">
              <b>Organizations</b>
              <br /> <br />
              <div className="d-flex align-content-center orgs">
                <img
                  className="mr-4"
                  height="42px"
                  width="97px"
                  src="/images/frpo_logo.png"
                  alt="logo"
                />
                <img
                  src="/images/Logo-BILD.svg"
                  height="61px"
                  width="43px"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="policy-layout row d-flex no-gutters flex-xs-column border-white border-top mt-4 pt-4">
            <div className="col-md-6">
              ©2019 The Benvenuto Group. All Rights Reserved.
            </div>
            <div className="col-md-6 text-right">
              <a href="/policy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
