import React, { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./Carousel.css";
class Home extends Component {
  lastClickTimestamp = new Date().getTime();
  constructor(props) {
    super(props);
    this.state = {
      items: [...props.items],
      currentIndex: 0,
      rotatedBack: false
    };
  }
  componentDidMount() {
    // this.startTimer();
  }
  startTimer() {
    // this.intervalId = setInterval(() => {
    //   this.rotate();
    // }, 8000);
  }
  rotate() {
    const { currentIndex, items } = this.state;
    let newIndex = 0;
    if (currentIndex < items.length - 1) {
      newIndex = currentIndex + 1;
    }
    this.setState({ currentIndex: newIndex, rotatedBack: false });
  }

  rotateBack() {
    const { currentIndex, items } = this.state;
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = items.length - 1;
    }
    this.setState({ currentIndex: newIndex, rotatedBack: true });
  }

  goToNextItem() {
    let now = new Date().getTime();
    if (now < this.lastClickTimestamp + 1000) return;
    this.lastClickTimestamp = now;
    clearInterval(this.intervalId);
    this.rotate();
    this.startTimer();
  }
  goToPreviousItem() {
    let now = new Date().getTime();
    if (now < this.lastClickTimestamp + 1000) return;
    this.lastClickTimestamp = now;
    clearInterval(this.intervalId);
    this.rotateBack();
    this.startTimer();
  }
  render() {
    const { items, currentIndex } = this.state; //rotatedBack
    // let cardItems = [];
    // let peekNext = items[currentIndex + 1];
    // let peekNext2 = items[currentIndex + 2];
    // if (!peekNext) {
    //   peekNext = items[0];
    //   peekNext2 = items[1];
    // }
    // if (!peekNext2) {
    //   peekNext2 = items[0];
    // }
    // cardItems.push(items[currentIndex]);
    // cardItems.push(peekNext);
    // cardItems.push(peekNext2);
    const featuredCards = items.map((item, index) => (
      <div id={item.id} key={item.id} className={"test-card-wrapper todo-item"}>
        <button
          className="btn"
          onClick={() => {
            this.setState({ currentIndex: index });
          }}
        >
          <div
            id="test-card-1"
            className={
              "test-card d-flex" +
              (index === currentIndex
                ? " active-card shadow"
                : " inactive-card")
            }
          >
            <img src={item.icon} alt="icon" />
          </div>
        </button>
      </div>
    ));
    const slides = items.map((item, index) => (
      <img
        key={item.id}
        className={"img-fluid " + ((index === currentIndex) ? "active" : "")}
        src={item.banner}
        alt="featured"
      />
    ));
    return (
      <div>
        <div className="featured-controls-mobile pt-3">
    <div className="featured-title">Featured: {items[currentIndex].sub_title}</div>
          <a className="featured-count" href={items[currentIndex].link} target="_blank" rel="noopener noreferrer">
            <div >
              {items[currentIndex].sub_title_two}
            </div>
          </a>
        </div>

        <div className="d-flex carousel-container">
            {slides}
        </div>
        <div className="carousel-bottom-controls pt-3 pb-3">
          <div className="featured-title">Featured: {items[currentIndex].sub_title}</div>
          <a className="featured-count" href={items[currentIndex].link} target="_blank" rel="noopener noreferrer">
            <div >
              {items[currentIndex].sub_title_two}
            </div>
          </a>

          <div className="carousel-card-container d-flex flex-nowrap">
            <ReactCSSTransitionGroup
              transitionName="card-slide"
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={1000}
            >
              {featuredCards}
            </ReactCSSTransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
