import React, { Component } from "react";
import "./Contact.css";
import Hero from "../components/Hero";

class Contact extends Component {
  state = { didInit: false };
  render() {
    const { content } = this.props;
    const layouts = content.map((item, index) => (
      <div key={index}>
        <div className="details-title mb-4">
          <div className="d-flex align-content-end">
            <img src={item.icon} alt="icon" />
            <div className="ml-3 pt-3">{item.title}</div>
          </div>
        </div>
        <div className="row">
          {item.items.map((info, index) => (
            <div key={index} className="col-md-4">
              <div className="detail-item-heading mt-3">{info.name}</div>
              {info.address.map((address, index) => (
                <div className="detail-item-data" key={index}>
                  {address}
                </div>
              ))}
              {info.hours ? (
                <div>
                  <div className="detail-item-heading mt-3">
                    Rental Office Hours
                  </div>
                  {info.hours.map((hours, index) => (
                    <div className="detail-item-data" key={index}>
                      {hours}
                    </div>
                  ))}
                </div>
              ) : null}
              <a className="detail-item-link" href={info.URL.link}>
                {info.URL.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    ));
    return (
      <div>
        <div className="contact-us pb-2">
          <div>
            <div className="dots dots-hero"></div>
            <div className="container">
              <div className="flex-grow-1 pb-4">
                <Hero heading="Always there to help" title="CONTACT US" />
              </div>
              <div className="contact-data-container">{layouts}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
