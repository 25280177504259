import React, { Component } from "react";
import Carousel from "../components/Carousel";
import "./Home.css";
class Home extends Component {
  state = { didInit: false };

  hoverHandler(item, index, hoverImageStyle) {
    this.setState({ hoverImage: item ,hoverSectionIndex: index, hoverImageStyle });
  }
  hoverLeaveHandler(item) {
    this.setState({ hoverImage: null,hoverSectionIndex: null, hoverImageStyle:null });
  }
  onPlayVideo = () => {
    this.setState({ playVideo: true });
  };
  attachToEnd(items,item){
    items.push(item);
    return items;
  }
  render() {
    const { content, featured } = this.props;
    const { hoverImage, hoverImageStyle, playVideo, hoverSectionIndex } = this.state;

    return (
      <div key={0}>
        <div className="dots dots-home"></div>
        <div className="title-container-layout">
          <div className="title-cointainer">
            WELCOME HOME
          </div>
          <div className="no-gutters">
            <Carousel items={featured} />
          </div>
        </div>
        <div className="home-property-container">
          <div className="container">
            {content.map((propCat, sectionIndex) => (
              <div key={sectionIndex} className="">
                {propCat.subItems ? (
                  <div className="row no-gutters">
                    
                    <div className="section-name col-sm-12">{propCat.name}</div>
                    
                    {this.attachToEnd(propCat.subItems
                      .filter(subItem => subItem.name !== "Coming Soon")
                      .map((subItem, index) => (
                        <div className="col-sm-6" key={index}>
                          <div className="section-region">{subItem.name}</div>
                          {subItem.items
                            .filter(item => !item.specialType)
                            .map((item, index) => {
                              let label;
                              if (item.selling) {
                                label = (<span className="tag selling">Selling Now</span>);
                              }
                              else if (item.leasing) {
                                label = (<span className="tag leasing">Leasing Now</span>);
                              }
                              return (
                                <div
                                  key={index}
                                  className="section-item"
                                  onMouseEnter={() =>
                                    this.hoverHandler((item.home_hover?item.home_hover:null), sectionIndex, item.customStyle)
                                  }
                                  onMouseLeave={() =>
                                    this.hoverLeaveHandler(item.home_hover?item.home_hover:null, sectionIndex, item.customStyle)
                                  }
                                >
                                  <a 
                                    href={item.link}
                                    target={item.external === true ? "_blank" : null}
                                    rel={item.external === true ? "noopener noreferrer" : null} >
                                    {item.name}
                                    {label}
                                    <img
                                      src="/images/long-arrow-right.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              );
                            })}
                        </div>
                      )),<div className="col">
                            {(hoverImage&&hoverSectionIndex===sectionIndex) && (
                              
                              <div className="selected-property" style={hoverImageStyle}>
                                {console.log(hoverImageStyle)&&<div></div>}
                                <img className={"one"+(propCat.limit_banner_height?" limit":"")} src={hoverImage+"-1.png"} alt="property" />
                                <img className={"two"+(propCat.limit_banner_height?" limit":"")} src={hoverImage+"-2.png"} alt="property" />
                              </div>
                            )}
                      </div>)}
                  </div>
                ) : (
                  <a href={propCat.link}>{propCat.name}</a>
                )}
              </div>
            ))}
            
          </div>
        </div>
        <div className="about-section text-center container">
          <span className="title">Who We Are</span>
          <div className="description pt-4">
            Malen Capital, a full-service investment property owner, and its
            sister company The Benvenuto Group, a luxury condominium development
            firm, create upscale living spaces and top performing commercial
            properties.
          </div>
          <div className="video-container mt-5">
            {playVideo ? (
              <video className="mt-4" controls autoPlay={true}>
                <source src="/videos/who-we-are.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div
                className="d-flex flex-column poster img-fluid"
                style={{
                  backgroundImage: `url('/images/video-poster.png')`
                }}
              >
                <div className="flex-grow-1 mx-auto">
                  <div
                    className="play-button"
                    onClick={this.onPlayVideo}
                    style={{
                      backgroundImage: `url("/images/play-button.svg")`
                    }}
                  ></div>
                </div>
              </div>
            )}
            <div className="dots dots-video"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
